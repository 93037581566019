/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

import { useRouter } from 'next/router';

import { Layout, Spinner } from '@hl-portals/ui';

import { useAppContext } from '../contexts/AppContext';

const getPrimaryOrder = (
  transactions: Transaction[]
): Transaction | undefined => {
  if (!transactions.length) return;

  return (
    transactions.find(
      (transaction) => transaction.attributes.client_most_recent
    ) || transactions[0]
  );
};

const Home = (): React.ReactElement => {
  const { appState, addAppState } = useAppContext();

  const router = useRouter();

  useEffect(() => {
    if (appState.transactions?.length) {
      const order = getPrimaryOrder(appState.transactions);

      addAppState({ order });
      router.push(`/transaction/${order.attributes.order_id}/dashboard`);
    }
  }, [appState.transactions]);

  return (
    <Layout padding="2rem" justifyContent="center" alignItems="center">
      <Spinner xl />
    </Layout>
  );
};

export default Home;
